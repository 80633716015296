import React from 'react'

const Footer = () => {
    return (
        <div className='footer-content py-4'>
            <div className='container'>
                Fortuna Protect - Copyright 2024, All Right Reserved
            </div>
        </div>
    )
}

export default Footer