import React from 'react'
import PartnersComponent from "../components/Partners"

const Partners = () => {
    return (
        <>
            <div className="subpage-banner d-flex flex-column justify-content-center align-items-center" id='banner'>
                <h1 className="text-white text-center fw-bold">Our Partners</h1>
            </div>
            <PartnersComponent />
        </>
    )
}

export default Partners