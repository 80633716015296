import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from "./pages/Homepage"
import Partners from "./pages/Partners"
import Header from './components/Header';
import Footer from './components/Footer';

function App() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path='/' element={<Homepage />} />
                <Route path='/partners' element={<Partners />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
