import React from 'react'
import Img from "../assets/img/info-card.jpg"

const InfoCards = () => {
    return (
        <div className="container section">
            <div className="row align-items-center">
                {/* Solda resim */}
                <div className="col-md-6">
                    <img
                        src={Img}
                        alt="Crypto Protection"
                        className="img-fluid"
                        width="500px"
                        height="300px"
                    />
                </div>
                {/* Sağda yazılar */}
                <div className="col-md-6">
                    <h2 className='mb-3 mobile-margin'>Investment Security, Peace of Mind for Your Future</h2>
                    <p>
                        Are you concerned about the volatility of cryptocurrency markets? With Fortuna Protect, you can safeguard your investments up to $100,000 and turn this volatility into an advantage. By insuring your portfolio against potential risks, you can more easily achieve your long-term investment goals.
                    </p>

                    <p>
                        Fortuna Protect offers a guarantee of up to $100,000 to reinforce your confidence in your investments. This way, you can take advantage of the opportunities in the cryptocurrency world without worrying about the future of your investments.
                    </p>

                    <p>
                        With Fortuna Protect, your investments are safeguarded from market fluctuations. Our insurance, which covers up to $100,000, ensures that the value of your investments is protected and potential losses are minimized.
                    </p>

                    <p>
                        To ensure our investors can trade with peace of mind, we strive to minimize all risks as a company. In this regard, investments up to $100,000 are protected in the event of any adverse situation affecting the company. This protection does not apply to losses resulting from transactions that are the investor's own responsibility.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default InfoCards