import React from 'react'
import Banner from '../components/Banner';
import InfoCards from '../components/InfoCards';
import FinalContent from '../components/FinalContent';
import InfoCards2 from '../components/InfoCards2';
import InfoCards3 from '../components/InfoCards3';
import InfoCards4 from '../components/InfoCards4';
import WhyUs from '../components/WhyUs';
import ContactUs from '../components/ContactUs';
import Advantages from '../components/Advantages';

const Homepage = () => {
    return (
        <>
            <Banner />
            <InfoCards />
            <FinalContent />
            <InfoCards2 />
            <InfoCards3 />
            <InfoCards4 />
            <WhyUs />
            <Advantages />
            <ContactUs />
        </>
    )
}

export default Homepage