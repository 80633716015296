import React from 'react'
import Img from "../assets/img/info-card-3.jpg"

const InfoCards3 = () => {
    return (
        <div className="info-card-3">
            <div className="container section">
                <div className="row align-items-center">
                    {/* Sağda yazılar */}
                    <div className="col-md-6">
                        <h2 className='mb-3'>Your Corporate Crypto Investments Are Protected</h2>
                        <p>
                            If you’re looking to enter the cryptocurrency market as a corporation, you’re in the right place. 20% of your investments are insured by Fortuna Protect, based in Bermuda. Bermuda's tax advantages and balanced regulations offer an ideal environment for corporate investors.
                        </p>
                    </div>
                    {/* Solda resim */}
                    <div className="col-md-6 text-end">
                        <img
                            src={Img}
                            alt="Crypto Protection"
                            className="img-fluid mobile-margin"
                            width="500px"
                            height="300px"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoCards3