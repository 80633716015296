import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from "../assets/img/fortuna-disi.png";

const Header = () => {
    const [active, setActive] = useState(false);
    const [activeLink, setActiveLink] = useState('/');
    const location = useLocation();

    useEffect(() => {
        // Scroll olayını dinlemek için event listener ekle
        const handleScroll = () => {
            if (window.scrollY >= 200) {
                setActive(true);
            } else {
                setActive(false);
            }

            // Aktif linkin güncellenmesi
            if (window.scrollY < document.querySelector('#last-section')?.offsetTop) {
                setActiveLink('/');
            }
            else if (window.scrollY < document.querySelector('#whyus')?.offsetTop) {
                setActiveLink('#whyus');
            } else if (window.scrollY < document.querySelector('#advantages')?.offsetTop) {
                setActiveLink('#advantages');
            } else if (window.scrollY < document.querySelector('#contact')?.offsetTop) {
                setActiveLink('#contact');
            }
        };

        window.addEventListener("scroll", handleScroll);

        // Temizlik işlemi
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Sayfa değiştiğinde aktif linki güncelle
        setActiveLink(location.pathname);
    }, [location]);

    return (
        <header className="header">
            <nav className={`navbar navbar-expand-lg navbar-light fixed-top py-3 ${active ? "header-active" : ""}`}>
                <div className="container">
                    <a className="navbar-brand" href="#">
                        <img src={Logo} alt="logo" width="200px" />
                    </a>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className={`nav-item ${activeLink === '/' ? 'active' : ''}`}>
                                <a className="nav-link" href={window.location.pathname === "/" ? "#banner" : "/"}>
                                    Homepage
                                </a>
                            </li>
                            <li className={`nav-item ${activeLink === '#whyus' ? 'active' : ''}`}>
                                <a className="nav-link" href={window.location.pathname === "/partners" ? "/" : "#whyus"}>
                                    Why us?
                                </a>
                            </li>
                            <li className={`nav-item ${activeLink === '/partners' ? 'active' : ''}`}>
                                <Link className="nav-link" to="/partners">
                                    Partners
                                </Link>
                            </li>
                            <li className={`nav-item ${activeLink === '#advantages' ? 'active' : ''}`}>
                                <a className="nav-link" href={window.location.pathname === "/partners" ? "/" : "#advantages"}>
                                    Our Advantages
                                </a>
                            </li>
                            <li className={`nav-item ${activeLink === '#contact' ? 'active' : ''}`}>
                                <a className="nav-link" href={window.location.pathname === "/partners" ? "/" : "#contact"}>
                                    Get in Touch
                                </a>
                            </li>
                        </ul>
                        <div className='ms-lg-4 mt-lg-0 mt-3'>
                            <button className='btn-secondary-v2 me-2'>Create an Account</button>
                            <button className='btn-outline-secondary-v2'>Login</button>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;