import React from 'react'

const WhyUs = () => {
    return (
        <div className='container my-5' id='whyus'>
            <h2 className='text-center content-title'>Why Choose us?</h2>
            <div className="row why-us-content">
                <div className="col-lg-4 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-4">
                            <img width="64" height="64" src="https://img.icons8.com/wired/64/34DA89/tax.png" alt="icon" className='mb-4' />
                            <h3 className='h4 mb-3'>Tax Optimization</h3>
                            <p className='mb-0'>Take advantage of tax benefits to reduce your investment costs.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-4">
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/34DA89/he.png" alt="icon" className='mb-4' />
                            <h3 className='h4 mb-3'>Dedicated Account Manager</h3>
                            <p className='mb-0'>Achieve your investment goals with personalized services.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-4">
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/34DA89/geography--v1.png" alt="icon" className='mb-4' />
                            <h3 className='h4 mb-3'>Regulation Compliant</h3>
                            <p className='mb-0'>We provide insurance solutions that comply with all international standards.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-4">
                            <img width="64" height="64" src="https://img.icons8.com/parakeet-line/64/34DA89/id-verified.png" alt="icon" className='mb-4' />
                            <h3 className='h4 mb-3'>Customized Just for You</h3>
                            <p className='mb-0'>We have designed it specifically for the needs of new investors like you.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-4">
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/34DA89/trust--v1.png" alt="icon" className='mb-4' />
                            <h3 className='h4 mb-3'>Trusted Partnership</h3>
                            <p className='mb-0'>We collaborate with leading companies in the industry.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-4">
                            <img width="64" height="64" src="https://img.icons8.com/ios/64/34DA89/donate.png" alt="icon" className='mb-4' />
                            <h3 className='h4 mb-3'>Peaceful Investment</h3>
                            <p className='mb-0'>With the peace of mind that your investments are secure, you can fully enjoy the excitement of the crypto world.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyUs