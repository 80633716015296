import React from 'react'

const Banner = () => {
    return (
        <div className="banner d-flex flex-column justify-content-center align-items-center" id='banner'>
            <h1 className="text-white text-center fw-bold">Don't Fear Volatility <br /> Secure Your Investments</h1>
        </div>
    )
}

export default Banner