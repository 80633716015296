import React from 'react'
import Bitkub from "../assets/img/bitkub.png"
import Cloudex from "../assets/img/logo-2.png"
import Oceanex from "../assets/img/oceanex.png"
import Catex from "../assets/img/catex.png"
import Coincheck from "../assets/img/coincheck.svg"
import Coinz from "../assets/img/coinz.png"
import Phoenix from "../assets/img/phoenix.png"
import Currency from "../assets/img/currency.png"
import Whitebit from "../assets/img/whitebit.png"
import Btctrade from "../assets/img/btctrade.webp"
import Bitflyer from "../assets/img/bitflyer.png"
import Indoex from "../assets/img/indoex.png"
import Bitvavo from "../assets/img/bitvavo.png"

const Partners = () => {
    const partnerList = [
        {
            logo: Bitkub,
            title: "Bitkub",
            desc: "Bitkub's vision is to become Southeast Asia's leading digital asset exchange, empowering financial freedom and inclusion through a secure and user-friendly platform. The company aims to drive blockchain adoption and become a trusted partner for investors in the evolving digital economy."
        },
        {
            logo: Cloudex,
            title: "Cloudex Crypto",
            desc: "Cloudex Crypto's vision is to become a premier global cryptocurrency exchange, delivering a secure, innovative, and user-centric platform for digital asset trading. The company aims to empower users with advanced trading tools and a seamless experience, driving the widespread adoption of cryptocurrencies through transparency and reliability."
        },
        {
            logo: Oceanex,
            title: "OceanEx",
            desc: "OceanEx's vision is to be a world-class digital asset trading platform, offering a secure and intelligent environment for cryptocurrency trading. The company aims to integrate advanced AI technology to provide innovative financial services, fostering the growth and adoption of digital assets globally."
        },
        {
            logo: Catex,
            title: "Catex",
            desc: "Catex's vision is to create a leading global cryptocurrency exchange that prioritizes security, efficiency, and user satisfaction. The company strives to build a robust and transparent trading platform, enhancing the accessibility and adoption of digital assets worldwide."
        },
        {
            logo: Coincheck,
            title: "Coincheck",
            desc: "Coincheck's vision is to be Japan's most trusted and user-friendly cryptocurrency exchange, driving the adoption of digital assets through innovation and security. The company is committed to creating a reliable platform where users can confidently trade and manage their cryptocurrencies in a regulated and transparent environment."
        },
        {
            logo: Coinz,
            title: "Coin.z",
            desc: "Coin.Z's vision is to become a leading global cryptocurrency exchange by offering a secure, innovative, and user-centric platform. The company aims to simplify digital asset trading and foster widespread adoption by providing a seamless and accessible experience for users worldwide."
        },
        {
            logo: Phoenix,
            title: "Phoenix.Trade",
            desc: "Phoenix.Trade's vision is to revolutionize the cryptocurrency trading experience by offering a cutting-edge, secure, and intuitive platform. The company aspires to empower traders of all levels, driving the global adoption of digital assets through innovation, transparency, and reliability."
        },
        {
            logo: Currency,
            title: "Currency",
            desc: "Currency's vision is to bridge the gap between traditional finance and the digital economy by providing a secure and regulated platform for trading both cryptocurrencies and tokenized assets. The company aims to empower users with seamless access to a wide range of financial instruments, fostering the adoption of digital assets in a transparent and user-friendly environment."
        },
        {
            logo: Whitebit,
            title: "Whitebit",
            desc: "WhiteBIT's vision is to become Europe's leading cryptocurrency exchange, offering a secure, transparent, and innovative platform for digital asset trading. The company is dedicated to enhancing the accessibility and adoption of cryptocurrencies globally, providing users with a reliable and user-friendly environment for managing their digital investments."
        },
        {
            logo: Btctrade,
            title: "BTC-Trade",
            desc: "BTC-Trade's vision is to be a premier global cryptocurrency exchange, delivering a secure and efficient platform for trading digital assets. The company aims to enhance user experience and drive the adoption of cryptocurrencies through advanced technology and reliable service."
        },
        {
            logo: Bitflyer,
            title: "bitFlyer",
            desc: "Bitflyer's vision is to be the leading global cryptocurrency exchange, providing a secure and innovative platform for digital asset trading. The company strives to bridge the gap between traditional finance and the crypto world by offering advanced technology, regulatory compliance, and a user-friendly experience."
        },
        {
            logo: Indoex,
            title: "IndoEx",
            desc: "IndoEx's vision is to be a leading global cryptocurrency exchange, offering a secure and intuitive platform for seamless digital asset trading. The company aims to foster widespread adoption of cryptocurrencies by providing innovative solutions and exceptional user experience across diverse markets."
        },
        {
            logo: Bitvavo,
            title: "Bitvavo",
            desc: "Bitvavo's vision is to be Europe's most accessible and user-friendly cryptocurrency exchange, providing a secure and efficient platform for digital asset trading. The company aims to simplify the crypto experience, making it easy for users of all levels to buy, sell, and manage their digital investments."
        },
    ]

    return (
        <div className="partners-content">
            <div className="container section" id='partners'>
                <h2 className="text-center content-title">Partner Institutions</h2>
                <div className="row justify-content-center align-items-center">
                    {
                        partnerList.map((e) => (
                            <div className="col-lg-8 pb-4 mt-5">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-lg-5">
                                        <img src={e.logo} alt="logo" width="80%" />
                                    </div>
                                    <div className="col-lg-7">
                                        <h5 className="partners-title">
                                            {e.title}
                                        </h5>
                                        <p className="mb-0 mt-3">
                                            {e.desc}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Partners