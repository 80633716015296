import React from 'react'
import Img from "../assets/img/info-card-4.jpg"

const InfoCards2 = () => {
    return (
        <div className="container section" id='last-section'>
            <div className="row align-items-center">
                {/* Solda resim */}
                <div className="col-md-6">
                    <img
                        src={Img}
                        alt="Crypto Protection"
                        className="img-fluid"
                        width="500px"
                        height="300px"
                    />
                </div>
                {/* Sağda yazılar */}
                <div className="col-md-6">
                    <h2 className='mb-3 mobile-margin'>Strengthen Your Portfolio, Reduce Your Risk!</h2>
                    <p>
                        As an experienced cryptocurrency investor, you want to make your portfolio even more secure. With Fortuna Protect, your investments are protected against unexpected situations such as cyberattacks and exchange collapses. Elevate your crypto investments with our advanced insurance solutions.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default InfoCards2