import React from 'react'
import Img from "../assets/img/info-card-2.jpg"

const InfoCards2 = () => {
    return (
        <div className="container section">
            <div className="row align-items-center">
                {/* Solda resim */}
                <div className="col-md-6">
                    <img
                        src={Img}
                        alt="Crypto Protection"
                        className="img-fluid"
                        width="500px"
                        height="300px"
                    />
                </div>
                {/* Sağda yazılar */}
                <div className="col-md-6">
                    <h2 className='mb-3 mobile-margin'>Safe Investment for Beginners</h2>
                    <p>
                        Do you want to step into the world of cryptocurrency but are worried about the risks? There's no need to worry anymore! With our specially developed insurance solution, 20% of your investments are covered by Fortuna Protect. This allows you to start your cryptocurrency investment confidently, without being affected by market fluctuations.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default InfoCards2