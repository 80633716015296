import React, { useState } from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onsubmit = async (e) => {
        e.preventDefault();
        const { firstName, lastName, email, phone } = formData;
        const data = {
            firstName,
            lastName,
            email,
            phoneNumber: phone
        };
        try {
            // await axios.post(`https://www.fortunaprotect.com/api/Form/SubmitForm`, data, {
            await axios.post(`${window.location.origin}/api/Form/SubmitForm`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            Swal.fire({
                title: "Successful",
                text: "Your message has been successfully sent.",
                icon: "success"
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        } catch (error) {
            console.error('Form submission error:', error);
            Swal.fire({
                title: "An error occurred.",
                text: "An error occurred. Please try again.",
                icon: "error"
            });
        }
    }

    return (
        <div className="container section" id='contact'>
            <h2 className="text-center content-title mb-4">Contact Us</h2>
            <div className="row justify-content-center">
                {/* İletişim Formu */}
                <div className="col-md-8 mt-lg-0 mt-4">
                    <div className="card card-style4">
                        <div className="card-body p-lg-5 p-4">
                            <h4 className='h3 mb-4'>Contact Form</h4>
                            <form onSubmit={onsubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div>
                                            <label htmlFor="firstName" className="form-label">
                                                Name <span className='required'>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="firstName"
                                                placeholder="Enter Your Name"
                                                className="form-control"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-lg-0 mt-3">
                                        <div>
                                            <label htmlFor="lastName" className="form-label">
                                                Surname <span className='required'>*</span>
                                            </label>
                                            <input
                                                type="text"
                                                name="lastName"
                                                placeholder='Enter Your Surname'
                                                className='form-control'
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-lg-3">
                                        <div>
                                            <label htmlFor="email" className="form-label">
                                                E-mail Address <span className='required'>*</span>
                                            </label>
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder='Enter Your E-mail Address'
                                                className='form-control'
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-lg-3">
                                        <div>
                                            <label htmlFor="phone" className="form-label">
                                                Phone Number <span className='required'>*</span>
                                            </label>
                                            <input
                                                type="tel"
                                                name="phone"
                                                placeholder='Enter Your Phone Number'
                                                className='form-control'
                                                value={formData.phone}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div>
                                            <label htmlFor="reference" className="form-label">
                                                Referral Code
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Your Referral Code"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn-primary-v2 w-100 mt-4 py-2">
                                    Send
                                </button>
                            </form>
                            <div className='d-flex align-items-center mt-5'>
                                <div className='contact-info me-3'>
                                    <img width="35" height="35" src="https://img.icons8.com/ios/35/34DA89/marker.png" alt="marker" />
                                </div>
                                <div>
                                    <h5>Location</h5>
                                    <p className="mb-0">C. Fernando Lopez Arvelo, 11, 38679 Costa Adeje, Santa Cruz de Tenerife, Spain</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs