import React from 'react'
import Img from "../assets/img/final-content.jpg"

const FinalContent = () => {
    return (
        <div className="final-content">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-lg-7">
                        <h3 className="final-header">Fortuna Protect Assurance</h3>
                        <p className="final-text text-white mb-0">
                            At Fortuna Protect, we are committed to protecting our investors' capital. In the event of unforeseen circumstances such as company bankruptcy, fraud, or similar situations, investments up to $100,000 are safeguarded. However, losses incurred by investors due to market fluctuations or personal errors in decision-making are not covered by this assurance.
                        </p>
                    </div>
                    <div className="col-lg-4">
                        <img
                            src={Img}
                            alt="Crypto Protection"
                            className="img-fluid mobile-margin"
                            width="415px"
                            height="200px"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinalContent