import React from 'react'
import Img from "../assets/img/advantages.jpg"

const Advantages = () => {
    return (
        <div className="info-card-3" id='advantages'>
            <div className='container section advantages-content'>
                <div className="row align-items-center">
                    {/* Solda resim */}
                    <div className="col-md-6">
                        <img
                            src={Img}
                            alt="Crypto Protection"
                            className="img-fluid"
                            width="500px"
                            height="300px"
                        />
                    </div>
                    {/* Sağda yazılar */}
                    <div className="col-md-6">
                        <h2 className='mb-3 mobile-margin'>Our Advantages</h2>
                        <div className='d-flex align-items-start mt-5'>
                            <img width="45" height="45" src="https://img.icons8.com/ios/45/34DA89/insurance-agent.png" alt="hospital-insurance" />
                            <div className='ms-4'>
                                <h5>Personalized Insurance</h5>
                                <p className="mb-0">Insurance solutions tailored specifically to your portfolio</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-start mt-5'>
                            <img width="40" height="40" src="https://img.icons8.com/pastel-glyph/40/34DA89/conclusion-contract.png" alt="hospital-insurance" />
                            <div className='ms-4'>
                                <h5>Smart Contract Integration</h5>
                                <p className="mb-0">Insurance services compatible with blockchain technology</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-start mt-5'>
                            <img width="40" height="40" src="https://img.icons8.com/ios/40/34DA89/technical-support.png" alt="hospital-insurance" />
                            <div className='ms-4'>
                                <h5>24/7 Customer Support</h5>
                                <p className="mb-0">We are always by your side.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advantages